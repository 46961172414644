<template>
  <div>
    <quill-editor
      v-model="content"
      ref="myQuillEditor"
      :options="editorOption"
      @change="onEditorChange($event)"
      @ready="onEditorReady($event)"
    >
    </quill-editor>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import Quill from "quill"; // 引入编辑器

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "@/styles/quill.scss";

// 自定义字体大小
let fontSizeStyle = Quill.import("attributors/style/size");
fontSizeStyle.whitelist = [
  "14px",
  "15px",
  "16px",
  "17px",
  "18px",
  "20px",
  "24px",
];
Quill.register(fontSizeStyle, true);

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  ["blockquote", "code-block"], // 引用  代码块
  [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  [{ script: "sub" }, { script: "super" }], // 上标/下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  [{ direction: "rtl" }], // 文本方向
  [{ size: fontSizeStyle.whitelist }], // custom dropdown
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  ["clean"], // 清除文本格式
  ["link", "image", "video"],
];
export default {
  data() {
    return {
      content: "",
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
          },
        },
      },
    };
  },
  components: {
    quillEditor,
  },
  methods: {
    // 准备富文本编辑器
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    // 内容改变事件
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", html);
      //this.content = html;
    },
  },
};
</script>

<style></style>
